import { auth, endpoints } from 'src/utils/axios';

export const delegateDesignation = async () => {
  const url = endpoints.delegateDesignation.list;
  const { data } = await auth.get(url);
  return data;
};

export const updatedelegateDesignation = async (value) => {
  const url = endpoints.delegateDesignation.update;
  const { data } = await auth.post(url, value);
  return data;
};
