import PropTypes from 'prop-types';
// @mui
import {
  Button,
  CardHeader,
  Divider,
  // Table,
  // TableBody,
  // TableCell,
  // // TableContainer,
  // TableRow,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';

import Iconify from 'src/components/iconify';
import { DMYDate, countDays, user } from 'src/locales/Utils/utils';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import React from 'react';
import { ROLES } from 'src/utils/Constant';
// import Scrollbar from 'src/components/scrollbar';
// import { TableHeadCustom } from 'src/components/table';

// ----------------------------------------------------------------------

export default function AccommodationDetails({ delegateDetails }) {
  // const names = delegateDetails?.pr_data?.map((item) => item.name);
  // const commaSeparatedNames = names?.join(', ');

  // const contact_no = delegateDetails?.pr_data?.map((item) => item.contact_no);
  // const commaSeparatedContact = contact_no?.join(', ');

  // const tableLabels = [
  //   { id: 'Name', label: 'Name', align: 'center' },
  //   { id: 'contact_no', label: 'Contact No.', align: 'center' },
  // ];

  const router = useRouter();

  return (
    <Grid container spacing={{ xs: 0.5, md: 2 }}>
      {/* Hotel Details */}
      <Grid xs={12} md={6}>
        {delegateDetails?.accommodation_filled_or_not !== 'no' ? (
          <Card sx={{ height: '100%' }}>
            <CardHeader title="Hotel Details" sx={{ pb: 2 }} />
            <Divider sx={{ borderStyle: 'dashed' }} />
            {delegateDetails?.hotel_data && (
              <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                  <Grid xs={12} sm={4}>
                    Name
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                  >
                    {delegateDetails?.hotel_data.hotel_name}
                  </Grid>
                </Grid>
                <Divider sx={{ borderStyle: 'solid' }} />
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                  <Grid xs={12} sm={4}>
                    Phone
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                  >
                    {delegateDetails?.hotel_data.hotel_contact_number || '-'}
                  </Grid>
                </Grid>
                <Divider sx={{ borderStyle: 'solid' }} />
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                  <Grid xs={12} sm={4}>
                    Days
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                  >
                    {/* {countDays(delegateDetails?.departure_date, delegateDetails?.arrival_date)} */}
                    {delegateDetails?.arrival_date === null
                      ? null
                      : `${countDays(
                          delegateDetails.departure_date,
                          delegateDetails.arrival_date
                        )}  ${
                          countDays(delegateDetails.departure_date, delegateDetails.arrival_date) <
                          2
                            ? 'Day'
                            : 'Days'
                        }`}
                  </Grid>
                </Grid>
                <Divider sx={{ borderStyle: 'solid' }} />
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                  <Grid xs={12} sm={4}>
                    From - To
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                  >
                    {DMYDate(delegateDetails?.arrival_date)} to{' '}
                    {DMYDate(delegateDetails?.departure_date)}
                  </Grid>
                </Grid>

                <Divider sx={{ borderStyle: 'solid' }} />
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                  <Grid xs={12} sm={4}>
                    Room Category
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                  >
                    {delegateDetails?.hotel_data?.room_category}
                  </Grid>
                </Grid>

                <Divider sx={{ borderStyle: 'solid' }} />
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                  <Grid xs={12} sm={4}>
                    Room Type
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{
                      typography: 'subtitle2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {delegateDetails?.hotel_data?.room_type}
                  </Grid>
                  <Divider sx={{ borderStyle: 'solid' }} />
                </Grid>
              </Stack>
            )}
            {!delegateDetails?.hotel_data && (
              <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                  <Grid xs={12} sm={4}>
                    Allocation
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                  >
                    <Typography color="primary">Pending</Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ borderStyle: 'solid' }} />
                <Button
                  sx={{ maxWidth: '150px' }}
                  variant="contained"
                  disabled={
                    ![ROLES.admin, ROLES.superadmin, ROLES.accommodation].includes(user?.role)
                  }
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={() => router.push(paths.irc.accommodation.hotel)}
                >
                  Book Hotel
                </Button>
              </Stack>
            )}
          </Card>
        ) : (
          <Card sx={{ height: '100%' }}>
            <CardHeader title="Accommodation" sx={{ pb: 2 }} />
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                {/* <Grid xs={12} sm={4}>
                  
                </Grid> */}
                <Grid xs={12} sm={12} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  <Typography color="primary">Not Registered</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
            </Stack>
          </Card>
        )}
      </Grid>

      {/* Vehicle */}
      <Grid xs={12} md={6}>
        <Card sx={{ height: '100%' }}>
          <CardHeader title="Vehicle Details" sx={{ pb: 2 }} />
          <Divider sx={{ borderStyle: 'dashed' }} />

          {delegateDetails?.vehicle ? (
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Vehicle Registration No
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.vehicle.car_registered_no}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Vehicle Model
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.vehicle.car_name}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Driver Name
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.vehicle.driver_name}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Contact No
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.vehicle.driver_contact_no}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Vehicle Type
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.vehicle.car_category}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
            </Stack>
          ) : (
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid item xs={12} sm={4}>
                  Allocation
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                >
                  <Typography color="primary">Pending</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Button
                sx={{ maxWidth: '150px' }}
                variant="contained"
                disabled={![ROLES.admin, ROLES.superadmin, ROLES.transport].includes(user?.role)}
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={() => router.push(paths.irc.vehicles.details)}
              >
                Book Vehicle
              </Button>
            </Stack>
          )}
        </Card>
      </Grid>

      {/* Tour Details */}
      <Grid item xs={12} md={6}>
        <Card sx={{ height: '100%' }}>
          <CardHeader title="Tour Details" sx={{ pb: 2 }} />

          {delegateDetails?.tour ? (
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Route Option
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.tour?.duration}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Places
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.tour?.route_place
                    ? JSON?.parse(delegateDetails?.tour?.route_place)?.join(' - ')
                    : ''}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  No of Person
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.tour?.no_person}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Payment Status
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  Done
                </Grid>
              </Grid>

              {/* <Divider sx={{ borderStyle: 'solid' }} /> */}
            </Stack>
          ) : (
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                {/* <Grid xs={12} sm={4}>
                  Reason
                </Grid> */}
                <Grid item xs={12} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  <Typography color="primary">Not Registered</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              {/* <Button
                sx={{ maxWidth: '150px' }}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Assign PR
              </Button> */}
            </Stack>
          )}
        </Card>
      </Grid>

      {/* PR Officer Details */}

      {/* <Grid xs={12} md={6}>
        <Card sx={{ height: '100%' }}>
          <CardHeader title="PR Officer Details" sx={{ pb: 2 }} />
          <Divider sx={{ borderStyle: 'dashed' }} />

          {delegateDetails?.pr_data?.length ? (
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid item xs={12}>
                  <Scrollbar>
                    <Table>
                      <TableHeadCustom headLabel={tableLabels} />
                      <TableBody
                        sx={{
                          '& .MuiTableCell-root': {
                            textAlign: 'center',
                          },
                        }}
                      >
                        {delegateDetails?.pr_data?.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row?.contact_no}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </Grid>
              </Grid>
            </Stack>
          ) : (
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid item xs={12} sm={4}>
                  Allocation
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                >
                  <Typography color="primary">Pending</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Button
                sx={{ maxWidth: '150px' }}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={() => router.push(paths.irc.pr)}
              >
                Assign PR
              </Button>
            </Stack>
          )}
        </Card>
      </Grid> */}
    </Grid>
  );
}

AccommodationDetails.propTypes = {
  // status: PropTypes.string,
  delegateDetails: PropTypes.any,
};
