/* eslint-disable react-hooks/exhaustive-deps */
// @mui
import {
  Button,
  Grid,
  InputAdornment,
  Pagination,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box } from '@mui/system';
// src
import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { getFilteredData } from 'src/api/common/Filters/filter.services';
import { getLadiesCommitteeList } from 'src/api/ladiesCommittee/ladiesCommittee.services';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import {
  // emptyRows,
  // getComparator,
  // TableEmptyRows,
  TableNoData,
  useTable,
} from 'src/components/table';
import { currencyFormate, currentDateTime, visuallyHidden } from 'src/locales/Utils/utils';
import { paths } from 'src/routes/paths';
import { FILTER_MODULE } from 'src/utils/Constant';
import { hasValue } from 'src/utils/irc.utils';
import { getDelegateGuestUsers } from 'src/api/DelegateList/delegateList.services';
import getErrorMessage from '../common/getErrorMessage/getErrorMessage';
// import getErrorMessage from '../../common/getErrorMessage/getErrorMessage';
// import ExhibitorsDetailsModal from '../../Modals/ExhibitorsDetailsModal';
// import ExhibitorsStallModal from '../../Modals/ExhibitorsStallModal';

const TABLE_HEAD = [
  { id: 'unique_code', label: 'Unique Code' },
  { id: 'name', label: 'Name' },
  { id: 'amount', label: 'Amount' },
  { id: 'payment_mode', label: 'Payment' },
  { id: 'comments', label: 'Comments' },
];

const defaultFilters = {
  //   exhibitors_name: '',
  //   route_option: null,
};

const DelegateGuests = () => {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();

  // const denseHeight = table.dense ? 52 : 72;

  const [tableData, setTableData] = useState([]);

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    lastPage: 1,
    total: 0,
    per_page: 25,
  });

  const [filters, setFilters] = useState(defaultFilters);
  const [exportData, setExportData] = useState(false);

  // const dataFiltered = applyFilter({
  //   inputData: tableData,
  //   comparator: getComparator(table.order, table.orderBy),
  //   filters,
  // });

  const notFound = !tableData.length;

  const onChangePage = (_event, newPage) => {
    const nextPage = newPage;

    setPaginationData({
      ...paginationData,
      currentPage: nextPage,
    });
  };

  const onFilters = useCallback(
    (name, value) => {
      // table.onResetPage();
      setPaginationData({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        per_page: 50,
      });
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const onSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === 'asc';
      if (id !== '') {
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
        onFilters('order_type', isAsc ? 'DESC' : 'ASC');
        onFilters('order_by', id);
      }
    },
    [order, orderBy]
  );

  // const handleFilterName = useCallback(
  //   (event) => {
  //     onFilters('exhibitors_name', event.target.value);
  //   },
  //   [onFilters]
  // );

  //   const handleFilterRouteOption = useCallback(
  //     (_e, value) => {
  //       onFilters('status', value === null ? null : value?.value);
  //     },
  //     [onFilters]
  //   );

  const searchQuery = useCallback(
    _.debounce((val) => {
      onFilters('search', val);
    }, 1000),
    []
  );

  const getHandleApiCall = (pageValue) => {
    const objHasValue = hasValue(filters);

    return Object.keys(objHasValue).length === 0 && !exportData
      ? getDelegateGuestUsers(pageValue)
      : getFilteredData(
          {
            filter: { ...objHasValue },
            ...pageValue,
            module: 'guest-user',
            // sub_module: FILTER_MODULE.LADIES_SUB_MODULE,
            columns: 1,
            export: exportData,
          },
          exportData && { responseType: 'blob' }
        );
  };

  const handleApiCall = useCallback(() => {
    const val = {
      page: paginationData?.currentPage,
    };

    // getExhibitorsList(val);
    getHandleApiCall(val)
      .then((res) => {
        if (exportData) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Accompanying Guest - ${currentDateTime(new Date())}.xlsx`);
          document.body.appendChild(link);
          link.click();
        } else {
          // const data = getSRNo(res?.data?.current_page, res?.data.per_page, res?.data?.data);
          setTableData(res?.data?.data);
          // setTableData(res?.data.data);
          setPaginationData({
            currentPage: res?.data.current_page,
            lastPage: res?.data.last_page,
            total: res?.data.total,
            per_page: res.per_page,
          });
        }
      })
      .catch((err) => enqueueSnackbar(getErrorMessage(err), { variant: 'error' }))
      .finally(() => setExportData(false));
  }, [paginationData?.currentPage, filters, exportData]);

  useEffect(() => {
    // if (StallModal.isOpen === false || StallModal.isApiCall) {
    handleApiCall();
    // }
  }, [paginationData?.currentPage, filters, exportData]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Helmet>
        <title>Accompanying Guest : IRC Admin</title>
      </Helmet>
      <CustomBreadcrumbs
        heading="Accompanying Guest "
        links={[{ name: 'Dashboard', href: paths.irc.root }, { name: 'Accompanying Guest' }]}
        sx={{
          mb: { xs: 3 },
        }}
        action={
          <Button
            variant="contained"
            sx={{ width: '100%' }}
            onClick={() => {
              setExportData(true);
            }}
          >
            <Iconify sx={{ marginRight: '5px' }} icon="solar:export-bold" />
            Export
          </Button>
        }
      />
      <Card>
        <Grid
          container
          sx={{
            padding: '15px',
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={filters.name}
              onChange={(event) => searchQuery(event.target.value)}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar style={{ maxHeight: '60vh' }}>
            <Table stickyHeader size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align || 'center'}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={{
                        width: headCell.width,
                        minWidth: headCell.minWidth,
                        maxWidth: headCell.maxWidth,
                      }}
                    >
                      {onSort ? (
                        <TableSortLabel
                          hideSortIcon
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => onSort(headCell.id)}
                        >
                          {headCell.label}

                          {orderBy === headCell.id ? (
                            <Box sx={{ ...visuallyHidden }}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  '& .MuiTableCell-root': {
                    textAlign: 'center',
                  },
                }}
              >
                {/* dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    ) */}
                {tableData?.map((row) => (
                  <TableRow key={row?.unique_code}>
                    <TableCell>{row?.unique_code}</TableCell>
                    <TableCell>{row?.name || '--'}</TableCell>
                    {/* <TableCell>{row?.amount || '--'}</TableCell> */}
                    <TableCell>{`₹ ${currencyFormate(row?.amount)}` || '-'}</TableCell>

                    <TableCell>{row?.payment_mode || '--'}</TableCell>

                    <TableCell>{row?.comments || '--'}</TableCell>
                  </TableRow>
                ))}

                {/* <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                      /> */}
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        {/* <Pagination
            component="div"
            color="primary"
            sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
            count={paginationData.lastPage}
            onChange={onChangePage}
            page={paginationData?.currentPage}
            variant="text"
          /> */}
        <Grid
          container
          sx={{
            padding: '15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p>Total : {paginationData?.total}</p>

          <Pagination
            component="div"
            color="primary"
            sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
            count={paginationData.lastPage}
            page={paginationData.currentPage}
            onChange={onChangePage}
            variant="text"
          />
        </Grid>
      </Card>
    </Container>
  );
};

export default DelegateGuests;

// function applyFilter({ inputData, comparator, filters }) {
//   const { exhibitors_name, route_option } = filters;

//   const stabilizedThis = inputData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   inputData = stabilizedThis.map((el) => el[0]);

//   if (route_option) {
//     inputData = inputData.filter((user) =>
//       route_option === 1 ? user?.both_no === 1 : user?.both_no !== 1
//     );
//   }

//   if (exhibitors_name) {
//     inputData = inputData?.filter(
//       (product) =>
//         product?.exhibitors_name?.toLowerCase().indexOf(exhibitors_name?.toLowerCase()) !== -1
//     );
//   }

//   return inputData;
// }
