/* eslint-disable react-hooks/exhaustive-deps */

// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// hooks
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';
//
import { useEffect, useState } from 'react';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';

// ----------------------------------------------------------------------

export default function NavMini() {
  // const { user } = useMockedUser();
  const user = JSON.parse(localStorage.getItem('user'));

  const [navigationData, setNavigationData] = useState([]);

  const navData = useNavData();

  //  for navigation

  const getRouteData = () => {
    // let data;
    const users = JSON.parse(localStorage.getItem('user'));
    // Filter routes for role

    if (navData) {
      const userRole = users?.role;

      const data = navData[0]?.items?.reduce((filteredData, row) => {
        if (row.roles?.includes(userRole)) {
          // Include the entire row if it matches the userRole
          filteredData.push(row);
        } else if (row.children) {
          // Check for matching child items with specific role
          const childrenWithRole = row.children.filter((item) => item?.roles?.includes(userRole));

          // If any matching child items are found, include the parent row with those children
          if (childrenWithRole.length > 0) {
            filteredData.push({
              ...row,
              children: childrenWithRole,
            });
          }
        }

        return filteredData;
      }, []);
      setNavigationData([
        {
          items: data,
        },
      ]);
    }
  };
  useEffect(() => {
    getRouteData();
  }, [navData]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini
          data={navigationData}
          config={{
            currentRole: user?.role,
          }}
        />
      </Stack>
    </Box>
  );
}
