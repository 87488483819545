/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import { NavSectionVertical } from 'src/components/nav-section';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
//
import { NavToggleButton } from '../_common';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  // const { user } = useMockedUser();
  const user = JSON.parse(localStorage.getItem('user'));

  const pathname = usePathname();

  const [navigationData, setNavigationData] = useState([]);

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  //  for navigation

  const getRouteData = () => {
    // let data;
    const users = JSON.parse(localStorage.getItem('user'));
    // Filter routes for role

    if (navData) {
      const userRole = users?.role;

      const data = navData[0]?.items?.reduce((filteredData, row) => {
        if (row.roles?.includes(userRole)) {
          // Include the entire row if it matches the userRole
          filteredData.push(row);
        } else if (row.children) {
          // Check for matching child items with specific role
          const childrenWithRole = row.children.filter((item) => item?.roles?.includes(userRole));

          // If any matching child items are found, include the parent row with those children
          if (childrenWithRole.length > 0) {
            filteredData.push({
              ...row,
              children: childrenWithRole,
            });
          }
        }

        return filteredData;
      }, []);
      setNavigationData([
        {
          items: data,
        },
      ]);
    }
  };
  useEffect(() => {
    getRouteData();
  }, [navData]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

      <NavSectionVertical
        data={navigationData}
        config={{
          currentRole: user?.role,
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavUpgrade /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
