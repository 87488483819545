/* eslint-disable react-hooks/exhaustive-deps */
import { m } from 'framer-motion';
import { useEffect, useState } from 'react';
// @mui
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getNotificationList, readNotifications } from 'src/api/notification/notification.services';
import { client } from 'src/utils/irc.utils';
import { RoleBasedGuard } from 'src/auth/guard';
import { ROLES } from 'src/utils/Constant';
import NotificationItem from './notification-item';

export default function NotificationsPopover() {
  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');

  const [notifications, setNotifications] = useState();

  const [apiCall, setApiCall] = useState(true);

  const [readDataState, setReadDataState] = useState(null);

  const totalUnRead = notifications && notifications['total-unread'];

  const channel = client.channels.get(process.env.REACT_APP_ABLY_CHANNEL);

  channel.subscribe('notification', (message) => {
    setApiCall(true);
  });

  const handleMarkAllAsRead = (dataValue) => {
    setApiCall(true);
    const sendData =
      readDataState !== null && readDataState !== undefined && dataValue !== 'all'
        ? readDataState
        : dataValue;
    if (dataValue !== null) {
      readNotifications(sendData)
        .then((res) => {})
        .catch((err) => {
          console.log('err: ', err);
        })
        .finally(() => {
          setApiCall(false);
          handleApi();
        });
    }
  };

  useEffect(() => {
    if (readDataState) handleMarkAllAsRead();
  }, [readDataState]);

  const handleApi = () => {
    getNotificationList()
      .then((res) => {
        setNotifications(res);
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => setApiCall(false));
  };
  useEffect(() => {
    if (apiCall) handleApi();
  }, [apiCall]);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={() => handleMarkAllAsRead('all')}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications?.data?.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            drawer={drawer}
            setReadDataState={setReadDataState}
          />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <RoleBasedGuard roles={[ROLES.admin, ROLES.superadmin]}>
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          color={drawer.value ? 'primary' : 'default'}
          onClick={drawer.onTrue}
        >
          <Badge badgeContent={notifications && notifications['total-unread']} color="error">
            <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
          </Badge>
        </IconButton>
      </RoleBasedGuard>
      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}
        <Divider />
        {renderList}
      </Drawer>
    </>
  );
}
