import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// assets
// import { ForbiddenIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
// import ComingSoonView from 'src/sections/coming-soon/view';
// import ComingSoonIllustration from 'src/assets/illustrations/coming-soon-illustration';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, children, sx }) {
  // Logic here to get current user role
  // const { user } = useMockedUser();
  const user = JSON.parse(localStorage.getItem('user'));
  // const currentRole = 'user';
  const currentRole = user?.role; // admin;

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      // <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
      //   <m.div variants={varBounce().in}>
      //     <Typography variant="h3" sx={{ mb: 2 }}>
      //       Permission Denied
      //     </Typography>
      //   </m.div>

      //   <m.div variants={varBounce().in}>
      //     <Typography sx={{ color: 'text.secondary' }}>
      //       You do not have permission to access this page
      //     </Typography>
      //   </m.div>

      //   <m.div variants={varBounce().in}>
      //     <ForbiddenIllustration
      //       sx={{
      //         height: 260,
      //         my: { xs: 5, sm: 10 },
      //       }}
      //     />
      //   </m.div>
      // </Container>
      <Container component={MotionContainer} sx={{ ...sx, height: '100%' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <m.div variants={varBounce().in}>
            <Typography variant="h1" sx={{ mb: 2 }}>
              Coming Soon!
            </Typography>
          </m.div>
          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              {/* {`Data is unavailable at the moment. We'll display updates as soon as we receive them.`} */}
              The relevant data is not available
            </Typography>
          </m.div>
        </Box>
        {/* <ComingSoonIllustration sx={{ my: 10, height: 240 }} /> */}
      </Container>
    ) : null;
  }

  return <> {children} </>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
};
