// @mui
import { CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import PropTypes from 'prop-types';
import { DateTimeFormat } from 'src/locales/Utils/utils';

//

// ----------------------------------------------------------------------

export default function DelegateGeneralDetails({ delegateDetails }) {
  // const date = delegateDetails.arrival_date

  return (
    <>
      {/* <Grid container spacing={{ xs: 0.5, md: 2 }}> */}
      {/* <Grid xs={12} md={12}> */}
      <Card sx={{ mt: 2 }}>
        <CardHeader title="User Details" sx={{ pb: 2 }} />
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Grid container spacing={{ xs: 0.5, md: 2 }}>
          <Grid xs={12} md={6}>
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Name
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.delegate_name || ' - '}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  IRC Membership No
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {/* {delegateDetails.}e */}

                  {delegateDetails?.irc_no || '-'}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Designation
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.designation || ' - '}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  QR ID
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.qr_id || ' - '}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Address
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.address || '-'}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Mobile
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.mobile_no || '- '}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Email
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2' }}>
                  {delegateDetails.email_id || '-'}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Name of Spouse
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.spouse_name || '-'}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              {/* <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Fight No
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.arrival_flight_no}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Airport
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.arrival_airport}
                </Grid>
              </Grid> */}
            </Stack>
          </Grid>
        </Grid>
      </Card>
      {/* </Grid> */}

      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid xs={12} md={6}>
          <Card sx={{ mt: 2 }}>
            <CardHeader title="Arrival" sx={{ pb: 2 }} />
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Date & Time
                </Grid>

                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.arrival_date &&
                    delegateDetails?.arrival_time &&
                    DateTimeFormat(
                      `${delegateDetails?.arrival_date} ${delegateDetails?.arrival_time}`
                    )}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              {/* <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Time
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.arrival_time}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} /> */}
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Mode
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.arrival_mode || ' '}
                </Grid>
              </Grid>

              {delegateDetails?.arrival_mode &&
                ['flight', 'train', 'air'].includes(
                  delegateDetails?.arrival_mode?.toLowerCase()
                ) && (
                  <>
                    {/* {['flight', 'train', 'air'].includes(
                      delegateDetails?.arrival_mode?.toLowerCase()
                    ) && ( */}
                    <>
                      <Divider sx={{ borderStyle: 'solid' }} />
                      <Grid container spacing={{ xs: 1, sm: 2 }}>
                        <Grid xs={12} sm={4}>
                          {delegateDetails.arrival_mode.toLowerCase() === 'air'
                            ? 'Airport'
                            : 'Station'}
                        </Grid>
                        <Grid
                          xs={12}
                          sm={6}
                          sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                        >
                          {delegateDetails.arrival_location}
                        </Grid>
                      </Grid>
                    </>
                    {/* )} */}

                    {/* {delegateDetails.arrival_class || delegateDetails.arrival_number ? ( */}
                    <>
                      <Divider sx={{ borderStyle: 'solid' }} />
                      <Grid container spacing={{ xs: 1, sm: 2 }}>
                        <Grid xs={12} sm={4} sx={{ textTransform: 'capitalize' }}>
                          {/* {delegateDetails.arrival_mode.toLowerCase() === 'train'
                            ? 'Class'
                            : delegateDetails.arrival_mode} */}
                          {delegateDetails.arrival_mode ? delegateDetails.arrival_mode : '-'}
                        </Grid>
                        <Grid
                          xs={12}
                          sm={6}
                          sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                        >
                          {delegateDetails.arrival_number || '-'}
                          {/* {delegateDetails.arrival_mode.toLowerCase() === 'train'
                              ? delegateDetails.arrival_class || '-'
                              : delegateDetails.arrival_number || '-'} */}
                        </Grid>
                      </Grid>
                    </>
                    {/* ) : (
                      ''
                    )} */}
                  </>
                )}

              {/* {delegateDetails?.arrival_mode !== (null || undefined) &&
                delegateDetails?.arrival_mode?.toLowerCase() === ('flight' || 'train') && (
                  <>
                    <Divider sx={{ borderStyle: 'solid' }} />
                    <Grid container spacing={{ xs: 1, sm: 2 }}>
                      <Grid xs={12} sm={4} sx={{ textTransform: 'capitalize' }}>
                        {delegateDetails?.arrival_mode?.toLowerCase() === 'train'
                          ? 'Class'
                          : delegateDetails?.arrival_mode}
                      </Grid>
                      <Grid
                        xs={12}
                        sm={6}
                        sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                      >
                        {delegateDetails?.arrival_mode?.toLowerCase() === 'train'
                          ? delegateDetails.arrival_class
                          : delegateDetails?.arrival_number}
                      </Grid>
                    </Grid>

                    <>
                      <Divider sx={{ borderStyle: 'solid' }} />
                      <Grid container spacing={{ xs: 1, sm: 2 }}>
                        <Grid xs={12} sm={4}>
                          {delegateDetails?.arrival_mode?.toLowerCase() === 'flight'
                            ? 'Airport'
                            : 'Station'}
                        </Grid>
                        <Grid
                          xs={12}
                          sm={6}
                          sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                        >
                          {delegateDetails.arrival_location}
                        </Grid>
                      </Grid>
                    </>
                  </>
                )} */}
            </Stack>
          </Card>
        </Grid>

        <Grid xs={12} md={6}>
          <Card sx={{ mt: 2 }}>
            <CardHeader title="Departure" sx={{ pb: 2 }} />
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack spacing={2} sx={{ p: 3, pt: 2 }}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Date & Time
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails?.departure_date &&
                    delegateDetails?.departure_date &&
                    DateTimeFormat(
                      `${delegateDetails?.departure_date} ${delegateDetails?.departure_time}`
                    )}
                </Grid>
              </Grid>
              <Divider sx={{ borderStyle: 'solid' }} />
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid xs={12} sm={4}>
                  Mode
                </Grid>
                <Grid xs={12} sm={6} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                  {delegateDetails.departure_mode || ' '}
                </Grid>
              </Grid>

              {delegateDetails?.departure_mode &&
                ['flight', 'train', 'air'].includes(
                  delegateDetails.departure_mode.toLowerCase()
                ) && (
                  <>
                    {/* <Divider sx={{ borderStyle: 'solid' }} />
                    <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ textTransform: 'capitalize' }}>
                      <Grid xs={12} sm={4}>
                        {delegateDetails.departure_mode.toLowerCase() === 'train'
                          ? 'Class'
                          : delegateDetails.departure_mode}
                      </Grid>
                      <Grid
                        xs={12}
                        sm={6}
                        sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                      >
                        {delegateDetails.departure_mode.toLowerCase() === 'train'
                          ? delegateDetails.departure_class || '-'
                          : delegateDetails.departure_number || '-'}
                      </Grid>
                    </Grid> */}

                    {['flight', 'train', 'air'].includes(
                      delegateDetails?.arrival_mode?.toLowerCase()
                    ) && (
                      <>
                        <Divider sx={{ borderStyle: 'solid' }} />
                        <Grid container spacing={{ xs: 1, sm: 2 }}>
                          <Grid xs={12} sm={4}>
                            {delegateDetails.departure_mode.toLowerCase() === 'air'
                              ? 'Airport'
                              : 'Station'}
                          </Grid>
                          <Grid
                            xs={12}
                            sm={6}
                            sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                          >
                            {delegateDetails.departure_location}
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <>
                      <Divider sx={{ borderStyle: 'solid' }} />
                      <Grid container spacing={{ xs: 1, sm: 2 }}>
                        <Grid xs={12} sm={4} sx={{ textTransform: 'capitalize' }}>
                          {delegateDetails.departure_mode ? delegateDetails.departure_mode : '-'}
                        </Grid>
                        <Grid
                          xs={12}
                          sm={6}
                          sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
                        >
                          {delegateDetails.departure_number || '-'}
                          {/* {delegateDetails.arrival_mode.toLowerCase() === 'train'
                              ? delegateDetails.arrival_class || '-'
                              : delegateDetails.arrival_number || '-'} */}
                        </Grid>
                      </Grid>
                    </>
                  </>
                )}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

DelegateGeneralDetails.propTypes = {
  delegateDetails: PropTypes.any,
};
