import PropTypes from 'prop-types';
// @mui

// components
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TableNoData, useTable } from 'src/components/table';
import { DateTimeFormat } from 'src/locales/Utils/utils';

// ----------------------------------------------------------------------

const IRCDialog = styled(Dialog)(({ theme }) => ({}));
// const IRcHotelInfoBox = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   justifyContent: 'flex-end',
//   alignItems: 'center',
//   // textAlign: 'right',
//   width: '100%',
//   // marginBottom: 25,
// }));

// const IRcHotelInfoLink = styled(Link)(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   // color: theme.palette.grey[900],
//   whiteSpace: 'nowrap',
// }));

const IRCHotelAvalibleRooms = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  '& h3': {
    margin: 0,
    marginRight: 10,
  },
}));

const TABLE_HEAD = [
  { id: 'srNo', label: 'Sr. No.', align: 'center' },
  { id: 'date_time', label: 'Date & Time', align: 'center' },
  { id: 'location', label: 'Event/Location', align: 'center' },
  // { id: 'time', label: 'Time', align: 'center' },
];

function QRHistoryDeleGate({ QRHisToryModal, setQRHisToryModal, delegateDetails }) {
  const table = useTable();

  return (
    <IRCDialog
      fullWidth
      maxWidth="md"
      open={QRHisToryModal?.isOpen}
      onClose={() =>
        setQRHisToryModal({
          ...QRHisToryModal,
          isOpen: false,
          // data: {},
        })
      }
    >
      {/* <FormProvider methods={methods} onSubmit={onSubmit}> */}
      <DialogTitle sx={{ mb: 2, pb: 0 }} textAlign="center" variant="h3">
        QR History
      </DialogTitle>

      <DialogContent>
        <Stack marginBottom={1}>
          <Grid container alignItems="center">
            <Grid xs={12}>
              <IRCHotelAvalibleRooms>
                <h3>QR ID : </h3>
                <Typography component="p">{delegateDetails?.unique_code || '-'}</Typography>
              </IRCHotelAvalibleRooms>
            </Grid>
          </Grid>
        </Stack>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={QRHisToryModal?.data?.data?.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />
              <TableBody>
                {QRHisToryModal?.data?.data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '& .MuiTableCell-root': {
                        textAlign: 'center',
                      },
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{DateTimeFormat(row?.date_time)}</TableCell>
                    <TableCell>{row.event_location || '-'}</TableCell>
                    {/* <TableCell>{row.event_time || '-'}</TableCell> */}
                  </TableRow>
                ))}
                <TableNoData notFound={!QRHisToryModal?.data?.data?.length} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </DialogContent>
    </IRCDialog>
  );
}

QRHistoryDeleGate.propTypes = {
  QRHisToryModal: PropTypes.object,
  delegateDetails: PropTypes.any,
  setQRHisToryModal: PropTypes.func,
};

export default QRHistoryDeleGate;
