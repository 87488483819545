/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Grid, Stack, TableCell, TableRow } from '@mui/material';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import {
  delegateDesignation,
  updatedelegateDesignation,
} from 'src/api/delegatedesignation/delegatedesignation.services';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableHeadCustom, TableNoData, getComparator, useTable } from 'src/components/table';
import { paths } from 'src/routes/paths';
import { toastMessage } from 'src/utils/toastmessages';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sr_no', label: 'Sr. No.', align: 'center' },
  { id: 'designation', label: 'Designation', align: 'center' },
  { id: 'equivalent_to', label: 'Equivalent To', align: 'center' },
];

// ----------------------------------------------------------------------

export default function DelegateDesignationList({ EventDate }) {
  const table = useTable({ defaultOrderBy: 'no-one-sort' });
  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    mode: 'onChange',

    // resolver: yupResolver(FormSchema),
    // defaultFilters,
  });

  const { watch } = methods;

  const [tableData, setTableData] = useState([]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  });

  const notFound = !dataFiltered.length;

  // eslint-disable-next-line consistent-return
  const handleUpdateRow = async (data, index) => {
    try {
      if (watch(`val-${data.designation}-${index}`) === undefined && data.designation) {
        return enqueueSnackbar('value already exist', { variant: 'error' });
      }

      if (watch(`val-${data.designation}-${index}`) === undefined && !data.designation) {
        return enqueueSnackbar('Field is required', { variant: 'error' });
      }
      const payload = {
        designation: data.designation,
        equivalent_to: watch(`val-${data.designation}-${index}`),
      };
      const res = await updatedelegateDesignation(payload);
      enqueueSnackbar(res?.message || toastMessage?.delegatedesignation);
    } catch (err) {
      enqueueSnackbar(err?.message || toastMessage?.error, { variant: 'error' });
    }
  };

  const handleApiCall = async () => {
    try {
      const res = await delegateDesignation();
      setTableData(res?.data);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    handleApiCall();
  }, []);

  return (
    <>
      {' '}
      <Helmet>
        <title> Delegate Designation : IRC Admin</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Delegate Designation List"
          links={[{ name: 'Dashboard', href: paths.irc.root }, { name: 'Delegate Designation' }]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar style={{ maxHeight: '60vh' }}>
              <Table stickyHeader size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {dataFiltered?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '& .MuiTableCell-root': {
                          textAlign: 'center',
                        },
                      }}
                    >
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>{index + 1}</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.designation}</TableCell>

                      <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FormProvider methods={methods}>
                          <Stack spacing={2}>
                            <RHFTextField
                              name={`val-${row.designation}-${index}`}
                              placeholder="designation"
                              defaultValue={row?.equivalent_to}
                            />
                          </Stack>
                        </FormProvider>
                        <Button
                          variant="contained"
                          onClick={() => handleUpdateRow(row, index)}
                          sx={{ marginLeft: '10px' }}
                        >
                          {row?.equivalent_to ? 'Edit' : 'Add'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, SOSData.length)}
              /> */}

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          {/* <TablePaginationCustom
        count={dataFiltered.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      /> */}

          <Grid
            container
            sx={{
              padding: '15px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>Total : {dataFiltered?.length}</p>
          </Grid>
        </Card>
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator }) {
  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);

  return inputData;
}
